.container__page {
    // background-color: #fff;
    .container__page-title {
        font-family: Manrope;
        font-size: 36px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #231f20;
    }
    .container__body {
        padding: 32px;
        background-color: #fff;
    }
    .container__table {
        margin-top: 32px;
        border-radius: 8px;
        background-color: #fff;
        border:1px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        .container__table-header {
            .container__table-title {
                height: 29px;
                font-family: Manrope;
                font-size: 21px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #231f20;
                border-bottom: solid 2px #f7f7f7;
                padding: 14px 26px;
            }
            .container__table-submenu {
                // margin-top: 20px;
                display: flex;
                justify-content: space-between;
                padding: 14px 26px;
            }
        }
        .container__table-body {
            // overflow: scroll;
            overflow-y: auto;
            max-height: 500px;
            table {
                width: 100%;
                table-layout: fixed;
                border-spacing: 0;
                overflow: scroll;
                
                thead {
                   
                    tr {
                        height: 48px;
                        td , th {
                            position: sticky;
                            position: -webkit-sticky;
                            top: 0;
                            font-family: "ManropeBold";
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #231f20;
                            background-color: #ffe88c;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            padding-left: 10px;
                            }
                            td:nth-child(1) {
                                width: 80px;
                                text-align: center;
                            }
                            th:nth-child(1) {
                                width: 80px;
                                text-align: center;
                            }
                    }
                    
                }
                tbody {
                    tr{
                        height: 48px;
                        td {
                            font-family: "Manrope";
                            font-size: 16px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: -0.29px;
                            color: #231f20;
                            vertical-align: middle;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            padding-left: 10px;
                        }
                        td:nth-child(1){
                            width: 80px;
                            text-align: center;
                        }
                    }
                    
                }
            }
            
            tr:nth-child(odd) {
                background-color: #ffffff;
            }
            
            tr:nth-child(even) {
                background-color: #f8f8f8;
            }
            .button-details {
                width: 78px;
                height: 32px;
                border-radius: 4px;
                background-color: #231f20;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fc0;
            }
        }
        .pagination {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0;
            align-items: center;
            .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
                background-color: #231f20 !important;
                color: #ffcc00 !important;
            }
        }
        
    }
}